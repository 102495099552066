<template>
  <div class="app-container">
    <template v-if="router.path.startsWith('/user')">
      <router-view />
    </template>
    <template v-else>
      <BasicLayout></BasicLayout>
    </template>
  </div>
</template>

<style scoped>
.app-container {
  //background-image: url("/src/assets/background2.jpg"); /* 替换为实际的图片路径 */
  background-size: cover;
  background-position: center;
  min-height: 100vh; /* 设置最小高度为视口高度 */
}
</style>
<script setup lang="ts">
import BasicLayout from "@/layouts/BasicLayout.vue";

import { onMounted } from "vue";
import { useRoute } from "vue-router";

const router = useRoute();
/**
 * 全局初始化项目，有全局单次调用的代码，都可以写到这
 */
const doInit = () => {
  console.log("hellow,欢迎来到我的项目");
};
onMounted(() => {
  doInit();
});
</script>
