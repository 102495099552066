<template>
  <div id="manageQuestionView">
    <a-form :model="searchParams" layout="inline" style="margin-left: 30px">
      <a-form-item field="title" label="名称" style="min-width: 240px">
        <a-input v-model="searchParams.title" placeholder="请输入名称" />
      </a-form-item>
      <a-form-item field="tags" label="标签" style="min-width: 240px">
        <a-input-tag v-model="searchParams.tags" placeholder="请输入标签" />
      </a-form-item>

      <a-form-item>
        <a-button type="primary" @click="doSearch">提交</a-button>
      </a-form-item>
    </a-form>
    <a-divider size="0" />
    <a-table
      :ref="tableRef"
      :columns="columns"
      :data="dataList"
      :pagination="{
        showTotal: true,
        pageSize: searchParams.pageSize,
        current: searchParams.current,
        total,
        showJumper: true,
        showPageSize: true,
      }"
      @page-change="onPageChange"
    >
      <template #tags="{ record }">
        <a-space wrap>
          <a-tag v-for="(tag, index) of record.tags" :key="index" color="green"
            >{{ tag }}
          </a-tag>
        </a-space>
      </template>
      <template #acceptedRate="{ record }">
        <a-space direction="vertical" :style="{ width: '100%' }">
          <a-progress
            v-if="
              record.submitNum != 0 &&
              (record.acceptedNum / record.submitNum).toFixed(2) > 0.5
            "
            :percent="
              record.submitNum
                ? (record.acceptedNum / record.submitNum).toFixed(2)
                : '0'
            "
          />
          <a-progress
            v-else
            :percent="
              record.submitNum
                ? (record.acceptedNum / record.submitNum).toFixed(2)
                : '0'
            "
            status="warning"
          />
        </a-space>
      </template>
      <template #createTime="{ record }">
        {{ moment(record.createTime).format("YYYY-MM-DD") }}
      </template>
      <template #optional="{ record }">
        <a-space>
          <a-button type="primary" @click="doQuestionPage(record)"
            >做题
          </a-button>
        </a-space>
      </template>
    </a-table>
  </div>
</template>

<script setup lang="ts">
import { onMounted, ref, watchEffect } from "vue";
import {
  Question,
  QuestionControllerService,
  QuestionQueryRequest,
} from "../../../backapi";
import message from "@arco-design/web-vue/es/message";

import { useRouter } from "vue-router";
import moment from "moment";
// import { router } from "@/router/routes";

const tableRef = ref();

const dataList = ref([]);
const total = ref(0);
const searchParams = ref<QuestionQueryRequest>({
  title: "",
  tags: [],
  pageSize: 10,
  current: 1,
});
// 创建一个数组来存储获取到的用户信息
// const userInfos = [];
// 计算属性

const loadData = async () => {
  const res = await QuestionControllerService.listQuestionVoByPageUsingPost(
    searchParams.value
  );
  if (res.code === 0) {
    dataList.value = res.data.records;
    total.value = res.data.total;
  } else {
    message.error("加载失败，" + res.message);
  }
};

/**
 * 监听 searchParams 变量，改变时触发页面的重新加载
 */
watchEffect(() => {
  loadData();
});

/**
 * 页面加载时，请求数据
 */
onMounted(() => {
  loadData();
});

const columns = [
  {
    title: "题号",
    dataIndex: "id",
    align: "center",
  },
  {
    title: "题目名称",
    dataIndex: "title",
    align: "center",
  },

  {
    title: "标签",
    dataIndex: "tags",
    slotName: "tags",
    align: "center",
  },
  {
    title: "通过率",
    slotName: "acceptedRate",
    dataIndex: "acceptedNum",
    sortable: {
      sortDirections: ["ascend", "descend"],
    },
  },

  {
    title: "创建时间",
    dataIndex: "createTime",
    align: "center",
    slotName: "createTime",
  },
  {
    title: "操作",
    slotName: "optional",
    align: "center",
  },
];

const onPageChange = (page: number) => {
  searchParams.value = {
    ...searchParams.value,
    current: page,
  };
};
const calculateAcceptedRate = (question: Question) => {
  return `${
    question.submitNum
      ? (question.acceptedNum / question.submitNum).toFixed(2) * 100
      : "0"
  }% `;
};
const router = useRouter();

/**
 * 修改 / 更新操作
 * @param question
 */
const doQuestionPage = (question: Question) => {
  router.push({
    path: `/view/question/${question.id}`,
  });
};
const doSearch = () => {
  //重置页号
  searchParams.value = {
    ...searchParams.value,
    current: 1,
  };
  // loadData();
};
</script>

<style scoped>
#manageQuestionView {
  max-width: 1280px;
  margin: 0 auto;
}
</style>
