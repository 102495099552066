<template>
  <div class="scrollable-container">
    <Viewer :value="value" :plugins="plugins" />
  </div>
</template>

<script setup lang="ts">
import gfm from "@bytemd/plugin-gfm";
import highlight from "@bytemd/plugin-highlight";
import { Viewer } from "@bytemd/vue-next";
import { defineProps, withDefaults } from "vue";

interface Props {
  value: string;
}

const plugins = [gfm(), highlight()];

const props = withDefaults(defineProps<Props>(), {
  value: () => "",
});
</script>

<style scoped>
.scrollable-container {
  min-height: 455px; /* 或者其他合适的高度 */
  max-height: 465px; /* 或者其他合适的高度 */
  overflow-y: auto; /* 或者使用 overflow-y: scroll */
}
</style>
