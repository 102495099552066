<template>
  <div class="app-container">
    <h2>用户注册</h2>

    <a-form
      style="max-width: 480px; margin: 0 auto"
      :model="form"
      @submit="handleSubmit"
      label-align="left"
      auto-label-width
    >
      <a-form-item field="userAccount" label="账号:">
        <a-input v-model="form.userAccount" placeholder="请输入账号" />
      </a-form-item>
      <a-form-item field="post" label="密码:">
        <a-input-password
          v-model="form.userPassword"
          placeholder="请输入密码"
        />
      </a-form-item>
      <a-form-item field="post" label="确认密码:">
        <a-input-password
          v-model="form.checkPassword"
          placeholder="请输入密码"
        />
      </a-form-item>
      <a-form-item align="center">
        <a-button type="primary" html-type="submit" style="width: 120px"
          >注册
        </a-button>
        <a-button
          type="primary"
          @click="doLogin"
          style="margin-left: 120px; width: 120px"
        >
          登录
        </a-button>
      </a-form-item>
    </a-form>
  </div>
</template>

<script setup lang="ts">
import { reactive } from "vue";
import { UserControllerService, UserRegisterRequest } from "../../../backapi";
import { useRouter } from "vue-router";
import { useStore } from "vuex";

const store = useStore();
const router = useRouter();
const form = reactive({
  userAccount: "",
  userPassword: "",
  checkPassword: "",
} as UserRegisterRequest);
/**
 * 表单信息
 * @param data
 */
const handleSubmit = async () => {
  //登录请求
  const res = await UserControllerService.userRegisterUsingPost(form);
  //登录成功，跳转到主页
  if (res.code === 0) {
    // alert("登录成功");
    //调用公共模块方法，获取登录信息
    await store.dispatch("user/getLoginUser");
    //跳转首页
    router.push({
      path: "/",
      replace: true,
    });
  } else {
    alert(res.message);
    // alert("登录失败");
  }
  // alert(JSON.stringify(form));
};
const doLogin = async () => {
  //调用公共模块方法，获取登录信息
  await store.dispatch("user/getLoginUser");
  //跳转首页
  router.push({
    path: "/",
    replace: true,
  });
};
</script>
<style scoped>
.app-container {
  margin-top: 60px;
  justify-content: center;
  align-items: center;
  background-size: cover;
  background-position: center;
}
</style>
