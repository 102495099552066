import axios from "axios";

// 添加请求拦截器
axios.interceptors.request.use(
  function (config) {
    // 在发送请求之前做些什么
    return config;
  },
  function (error) {
    // 对请求错误做些什么
    return Promise.reject(error);
  }
);

// 添加响应拦截器
axios.interceptors.response.use(
  function (response) {
    console.log("全局响应", response);
    // if (response.data.code === 40100) {
    //   // 避免多次跳转，设置一个标志
    //   // if (!window.__hasRedirected__) {
    //   //   window.__hasRedirected__ = true; // 标记已处理
    //   //   // 注意这里的URL拼接应根据实际需要调整
    //   //   const redirectPath =
    //   //     "/user/login?redirect=" + encodeURIComponent(window.location.href);
    //   //   window.location.href = redirectPath;
    //   // }
    //   // window.location.href = "/user/login?redirect=${redirect}";
    // }
    // 2xx 范围内的状态码都会触发该函数。
    // 对响应数据做点什么
    return response;
  },
  function (error) {
    // 超出 2xx 范围的状态码都会触发该函数。
    // 对响应错误做点什么
    return Promise.reject(error);
  }
);
