<template>
  <div id="viewQuestionView">
    <div class="layout-demo">
      <a-layout>
        <a-layout-sider
          :resize-directions="['right']"
          style="width: 600px; height: 65vh"
        >
          <a-scrollbar type="embed" style="height: 65vh; overflow: auto">
            <a-tabs default-active-key="question">
              <a-tab-pane key="question" title="题目">
                <a-card v-if="question" :title="question.title">
                  <a-descriptions title="判题条件" column="ResponsiveValue">
                    <a-descriptions-item label="时间限制（ms）:">
                      <a-tag>{{ question.judgeConfig.timeLimit ?? 0 }}</a-tag>
                    </a-descriptions-item>
                    <a-descriptions-item label="内存限制（b）:">
                      <a-tag
                        >{{ question.judgeConfig.memoryLimit ?? 0 }}
                      </a-tag>
                    </a-descriptions-item>
                    <a-descriptions-item label="堆栈限制（KB）:">
                      <a-tag>{{ question.judgeConfig.stackLimit ?? 0 }}</a-tag>
                    </a-descriptions-item>
                  </a-descriptions>

                  <template #extra>
                    <a-space wrap>
                      <a-tag
                        v-for="(tag, index) of question.tags"
                        :key="index"
                        color="green"
                        >{{ tag }}
                      </a-tag>
                    </a-space>
                  </template>
                  <MdView :value="question.content || ''" />
                </a-card>
              </a-tab-pane>

              <a-tab-pane key="comment" title="AI评估">
                <a-space direction="vertical" size="large" style="width: 100%">
                  <!--                  <a-textarea-->
                  <!--                    :model-value="aiResult"-->
                  <!--                    :value="aiResult"-->
                  <!--                    style="height: 250px; overflow-y: auto"-->
                  <!--                    placeholder="ai评估结果"-->
                  <!--                  />-->
                  <MdView :value="aiResult || 'AI评估'" />
                </a-space>
                <div style="display: flex; justify-content: flex-end">
                  <a-button
                    type="primary"
                    style="min-width: 100px"
                    @click="doSubmitByAI"
                    :loading="aiLoading"
                  >
                    AI评估
                  </a-button>
                </div>
              </a-tab-pane>
              <a-tab-pane key="answer" title="答案" disabled>
                <!--            {{(question && question.answer) || ''}}-->
              </a-tab-pane>
            </a-tabs>
          </a-scrollbar>
        </a-layout-sider>

        <a-layout-content>
          <div>
            <a-form
              :model="form"
              layout="inline"
              style="background-color: #121c24"
            >
              <a-form-item
                field="submitLanguage"
                style="margin-left: 10px; margin-top: 5px"
              >
                <a-select
                  v-model="form.submitLanguage"
                  placeholder="选择编程语言"
                  style="background-color: #bbbbbb"
                >
                  <a-option>java</a-option>
                  <a-option>cpp</a-option>
                  <a-option>c</a-option>
                  <a-option>python</a-option>
                  <a-option>javascript</a-option>
                </a-select>
              </a-form-item>
            </a-form>
            <CodeEditor
              :value="form.submitCode"
              :language="form.submitLanguage"
              :handle-change="changeCode"
            />
            <a-form
              :model="form"
              layout="inline"
              style="
                background-color: #121c24;
                justify-content: right;
                margin-right: 5px;
              "
            >
              <a-button
                type="outline"
                style="min-width: 120px; margin-right: 10px"
                size="large"
                status="danger"
                @click="goToPreviousQuestion"
                :disabled="!previousQuestionId"
                >上一题
              </a-button>
              <a-button
                type="outline"
                @click="goToNextQuestion"
                style="min-width: 120px; margin-right: 10px"
                size="large"
                status="danger"
                :disabled="!nextQuestionId"
                >下一题
              </a-button>
              <a-button
                type="outline"
                color="green"
                style="min-width: 120px; margin-right: 10px"
                size="large"
                :loading="Testloading"
                @click="doSubmitBySync"
              >
                调试代码
              </a-button>
              <a-button
                type="primary"
                style="min-width: 120px; margin-right: 10px; margin-bottom: 5px"
                size="large"
                @click="doSubmit"
              >
                测评并提交
              </a-button>
            </a-form>
          </div>
        </a-layout-content>
      </a-layout>
      <div>
        <div style="margin-top: 10px">
          <a-card>
            <a-descriptions :title="运行结果" column="ResponsiveValue">
              <a-descriptions-item label="运行结果:">
                <a-tag
                  v-if="runResult?.message === '通过'"
                  color="blue"
                  size="large"
                  >{{ runResult?.message ?? 0 }}
                </a-tag>
                <a-tag v-else color="red" size="large"
                  >{{ runResult?.message ?? null }}
                </a-tag>
              </a-descriptions-item>
              <a-descriptions-item label="内存使用:">
                <a-tag size="large">{{ runResult?.memory ?? 0 }} b</a-tag>
              </a-descriptions-item>
              <a-descriptions-item label="耗时:">
                <a-tag size="large">{{ runResult?.time ?? 0 }} ms</a-tag>
              </a-descriptions-item>
            </a-descriptions>
            <a-descriptions>
              <a-descriptions-item label="预期输出:">
                <a-tag size="large"> {{ runResult?.expected ?? null }}</a-tag>
              </a-descriptions-item>
              <a-descriptions-item label="实际输出:">
                <a-tag size="large">{{ runResult?.output ?? null }}</a-tag>
              </a-descriptions-item>
            </a-descriptions>
          </a-card>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { onMounted, onUnmounted, ref } from "vue";
import {
  QuestionControllerService,
  QuestionSubmitAddRequest,
  QuestionVO,
} from "../../../backapi";
import message from "@arco-design/web-vue/es/message";
import CodeEditor from "@/components/CodeEditor.vue";
import MdView from "@/components/MdView.vue";
import { onBeforeRouteUpdate, useRouter } from "vue-router";

const question = ref<QuestionVO>();
const router = useRouter();
const aiLoading = ref(false);
const runResult = ref(null);
const currentQuestionId = ref<string>("");
const Testloading = ref(false);

/**
 * 获取到动态路由 id
 */
interface Props {
  id: string;
}

const size = 0.5;
// eslint-disable-next-line no-undef
const props = withDefaults(defineProps<Props>(), {
  id: () => "",
});

/**
 * 代码编辑器中 默认程序
 */
let codeDefaultValue = ref(
  "public class Main {\n" +
    "    public static void main(String[] args) {\n" +
    '        System.out.println("Hello, World!");\n' +
    "    }\n" +
    "}\n"
);

/**
 * 提交代码
 */
const form = ref<QuestionSubmitAddRequest>({
  submitLanguage: "java",
  // submitCode: generateDefaultCode("java"),
  submitCode: codeDefaultValue as unknown as string,
});

/**
 * 代码编辑器中 改变代码
 * @param code
 */
const changeCode = (code: string) => {
  form.value.submitCode = code;
};

/**
 * 提交代码
 */
const doSubmit = async () => {
  if (!question.value?.id) {
    return;
  }

  const res = await QuestionControllerService.doQuestionSubmitUsingPost({
    ...form.value,
    questionId: question.value.id,
  });
  if (res.code === 0) {
    router.push({
      path: "/question/submitList",
    });
    message.success("提交成功");
  } else {
    message.error("提交失败，" + res.message);
  }
};
//代码调试
const doSubmitBySync = async () => {
  Testloading.value = true;
  if (!question.value?.id) {
    return;
  }
  runResult.value = "";
  const res = await QuestionControllerService.doQuestionSubmitSyncUsingPost({
    ...form.value,
    questionId: question.value.id,
  });
  console.log("res", res.data.judgeInfo);
  if (res.code === 0) {
    runResult.value = {
      message: res?.data?.judgeInfo?.message,
      memory: res?.data?.judgeInfo?.memory,
      time: res?.data?.judgeInfo?.time,
      output: res?.data?.output,
      expected: res?.data?.judgeCase,
    };
  } else {
    message.error("运行失败，" + res.message);
  }
  Testloading.value = false;
};

// 上一题-》下一题
const previousQuestionId = ref<string | null>(null);
const nextQuestionId = ref<string | null>(null);
const getAdjacentQuestions = async (currentId: string) => {
  const res = await QuestionControllerService.listMyQuestionVoByPageUsingPost(
    {}
  );
  if (res.code === 0) {
    const questions = res.data?.records;
    const currentIndex = questions.findIndex((q) => q.id === currentId);
    if (currentIndex === 0) {
      previousQuestionId.value = null;
      nextQuestionId.value = questions[currentIndex + 1].id;
    }
    if (currentIndex > 0) {
      previousQuestionId.value = questions[currentIndex - 1].id;
    }
    if (currentIndex === questions.length - 1) {
      previousQuestionId.value = questions[currentIndex - 1].id;
      nextQuestionId.value = null;
    }
    if (currentIndex < questions.length - 1) {
      nextQuestionId.value = questions[currentIndex + 1].id;
    }
  } else {
    message.error("获取题目列表失败，" + res.message);
  }
};
const route = useRouter();
const goToPreviousQuestion = async () => {
  if (previousQuestionId.value) {
    currentQuestionId.value = previousQuestionId.value;
    route.replace(`/view/question/${previousQuestionId.value}`);
    await loadData(currentQuestionId.value); // 重新加载数据
    //重写加载页面
  } else {
    message.warning("没有下一题了");
  }
};

const goToNextQuestion = async () => {
  if (nextQuestionId.value) {
    route.replace(`/view/question/${nextQuestionId.value}`);
    currentQuestionId.value = nextQuestionId.value;
    await loadData(currentQuestionId.value); // 重新加载数据
  } else {
    message.warning("没有上一题了");
  }
};
onBeforeRouteUpdate(async (to, from) => {
  if (to.params.id) {
    await getAdjacentQuestion(to.params.id as string);
  }
});
const getAdjacentQuestion = (currentId: string) => {
  currentQuestionId.value = currentId;
  loadData(currentId);
};

/**
 * ai评估结果
 */
let aiResult = ref("");
const eventSource = ref(null);
//当aiResult改变时，重新加载

// 构建查询参数字符串
const buildQueryParams = () => {
  const params = new URLSearchParams();
  Object.keys(form.value).forEach((key) => {
    params.append(key, form.value[key]);
  });
  params.append("questionId", question.value.id);
  return params.toString();
};
// 定义方法
const startSSE = () => {
  aiResult.value = "";
  if (eventSource.value) {
    eventSource.value.close();
  }
  const url = `http://localhost:8121/api/question/questionSubmit/do/ai/SSe?${buildQueryParams()}`;

  eventSource.value = new EventSource(url, {
    withCredentials: true, // 启用携带 cookie
  });

  eventSource.value.onmessage = (event) => {
    //如果evnet.data为空转换为换行符，当aiResult最后四个字符为java时，且当前为空格时，则添加markdown代码块格式

    if (event.data.trim() === "") {
      if (aiResult.value.endsWith("java\n")) {
        aiResult.value += "\n```";
      } else {
        aiResult.value += "  \n";
      }
    } else {
      aiResult.value += event.data;
    }
    // aiResult.value += event.data;

    console.log("接收到消息:", event.data);
  };

  eventSource.value.onerror = (event) => {
    if (event.eventPhase === EventSource.CLOSED) {
      console.log("连接关闭");
      eventSource.value.close();
      aiLoading.value = false;
    }
  };

  eventSource.value.onopen = () => {
    console.log("连接打开");
    aiLoading.value = true;
  };
};
// 处理SSE消息
// const handleSseMessage = (data) => {
//   if (data.trim() === '') {
//     aiResult.value += '\n'; // 空消息表示换行
//   } else {
//     aiResult.value += data;
//   }
//   console.log("接收到消息:", data);
// };
onUnmounted(() => {
  if (eventSource.value) {
    eventSource.value.close();
  }
});
const stopSSE = () => {
  if (eventSource.value) {
    eventSource.value.close();
    aiLoading.value = false;
    saveMessage();
  }
};

const saveMessage = () => {
  // 保存消息的逻辑
  console.log("保存消息:", aiResult.value);
};
const doSubmitByAI = async () => {
  aiLoading.value = true;
  if (!question.value?.id) {
    return;
  }
  const res = await QuestionControllerService.doQuestionSubmitByAiUsingPost({
    ...form.value,
    questionId: question.value.id,
  });
  if (res.code === 0) {
    console.log("res:" + res);
    aiResult = res.data;
    message.success("提交成功");
  } else {
    message.error("提交失败，" + res.message);
  }
  aiLoading.value = false;
};

const loadData = async (ids?: string) => {
  const res = await QuestionControllerService.getQuestionVoByIdUsingGet(ids);
  if (res.code === 0) {
    question.value = res.data;
    getAdjacentQuestions(res.data.id);
  } else {
    message.error("加载失败，" + res.message);
  }
};

/**
 * 页面加载时，请求数据
 */
onMounted(() => {
  loadData(props.id as any);
});
</script>

<style scoped>
#viewQuestionView {
  max-width: 1600px;
  margin: 0 auto;
}

#viewQuestionView .arco-space-horizontal .arco-space-item {
  margin-bottom: 0 !important;
}
</style>
