<template>
  <div id="addQuestionView">
    <div
      style="
        font-size: 32px;
        text-align: center;
        font-weight: bold;
        margin-bottom: 16px;
      "
      class="title"
    >
      {{ dynamicContent }}
    </div>

    <a-form :model="form">
      <a-form-item field="title" label="标题">
        <a-input v-model="form.title" placeholder="请输入标题" />
      </a-form-item>
      <a-form-item field="tags" label="标签">
        <a-input-tag v-model="form.tags" placeholder="请选择标签" allow-clear />
      </a-form-item>

      <a-form-item field="content" label="题目内容">
        <MdEditor :value="form.content" :handle-change="onContentChange" />
      </a-form-item>
      <a-form-item field="answer" label="答案">
        <MdEditor :value="form.answer" :handle-change="onAnswerChange" />
      </a-form-item>

      <!-- 判题配置嵌套表单 https://arco.design/vue/component/form-->
      <a-form-item label="判题配置" :content-flex="false" :merge-props="false">
        <a-space direction="vertical" style="min-width: 480px">
          <a-form-item field="judgeConfig.memoryLimit" label="内存限制">
            <a-input-number
              v-model="form.judgeConfig.memoryLimit"
              placeholder="请输入内存限制"
              mode="button"
              min="0"
              size="large"
            />
          </a-form-item>
          <a-form-item field="judgeConfig.timeLimit" label="时间限制">
            <a-input-number
              v-model="form.judgeConfig.timeLimit"
              placeholder="请输入时间消耗"
              mode="button"
              min="0"
              size="large"
            />
          </a-form-item>
          <a-form-item field="judgeConfig.stackLimit" label="堆栈限制">
            <a-input-number
              v-model="form.judgeConfig.stackLimit"
              placeholder="请输入堆栈限制"
              mode="button"
              min="0"
              size="large"
            />
          </a-form-item>
        </a-space>
      </a-form-item>

      <!--    判题用例 动态表单-->
      <a-form-item
        v-for="(judgeCaseItem, index) of form.judgeCase"
        :field="`form.judgeCase[${index}].input`"
        :label="`测试用例 ${index}`"
        :key="index"
      >
        <a-space direction="vertical" style="min-width: 480px">
          <a-form-item
            :field="`form.judgeCase[${index}].input`"
            :label="`输入用例-${index}`"
            :key="index"
          >
            <a-input
              v-model="judgeCaseItem.input"
              placeholder="请输入测试输入用例..."
            />
          </a-form-item>

          <a-form-item
            :field="`form.judgeCase[${index}].output`"
            :label="`输出用例-${index}`"
            :key="index"
          >
            <a-input
              v-model="judgeCaseItem.output"
              placeholder="请输入测试输出用例..."
            />
          </a-form-item>

          <a-button
            @click="handleDelete(index)"
            status="danger"
            :style="{ marginLeft: '15px' }"
          >
            <template #icon>
              <icon-delete />
            </template>
            删除
          </a-button>
        </a-space>
      </a-form-item>

      <a-form-item>
        <a-button
          @click="handleAdd"
          type="outline"
          status="success"
          :style="{ marginRight: '10px' }"
        >
          <template #icon>
            <icon-plus />
          </template>
          新增用例
        </a-button>
      </a-form-item>

      <a-form-item align="center">
        <a-button @click="doSubmit" type="primary" style="min-width: 150px"
          >提交
        </a-button>
      </a-form-item>
    </a-form>
  </div>
</template>
<script setup lang="ts">
// eslint-disable-next-line no-undef
import { onMounted, ref } from "vue";
import MdEditor from "@/components/MdEditor.vue";
import { IconDelete, IconPlus } from "@arco-design/web-vue/es/icon";
import { QuestionControllerService } from "../../../backapi";
import { Message } from "@arco-design/web-vue";
import { useRoute, useRouter } from "vue-router";

const router = useRouter();
const route = useRoute();
let dynamicContent = ref("创建题目");
const form = ref({
  answer: "",
  content: "",
  judgeCase: [
    {
      input: "",
      output: "",
    },
  ],
  judgeConfig: {
    memoryLimit: 110,
    stackLimit: 1110,
    timeLimit: 10000,
  },
  tags: [],
  title: "",
});
/**
 * 根据题目 id 获取老的数据
 */
const loadData = async () => {
  const id = route.query.id;
  if (!id) {
    dynamicContent = "创建题目";
    return;
  }
  dynamicContent = "更新题目";
  const res = await QuestionControllerService.getQuestionByIdUsingGet(
    id as any
  );
  if (res.code === 0) {
    form.value = res.data as any;
    // json 转 js 对象
    if (!form.value.judgeCase) {
      form.value.judgeCase = [
        {
          input: "",
          output: "",
        },
      ];
    } else {
      form.value.judgeCase = JSON.parse(form.value.judgeCase as any);
    }

    if (!form.value.judgeConfig) {
      form.value.judgeConfig = {
        memoryLimit: 1000,
        stackLimit: 1000,
        timeLimit: 1000,
      };
    } else {
      form.value.judgeConfig = JSON.parse(form.value.judgeConfig as any);
    }
    if (!form.value.tags) {
      form.value.tags = [];
    } else {
      form.value.tags = JSON.parse(form.value.tags as any);
    }
  } else {
    Message.error("加载失败，" + res.message);
  }
};

onMounted(() => {
  loadData();
});

// 如果页面地址包含 update，视为更新页面
const updatePage = route.path.includes("update");
console.log("是否有" + updatePage);

/**
 * 提交表单，区分更新和创建
 */
const doSubmit = async () => {
  console.log(form);
  if (updatePage) {
    const res = await QuestionControllerService.editQuestionUsingPost(
      form.value
    );
    if (res.code === 0) {
      // eslint-disable-next-line no-undef
      Message.success("更新成功");
      //更新成功返回题目管理页面
      await router.push({
        path: "/question/manage",
        replace: true,
      });
    } else {
      Message.success("更新失败" + res.message);
    }
  } else {
    const res = await QuestionControllerService.addQuestionUsingPost(
      form.value
    );
    if (res.code === 0) {
      // eslint-disable-next-line no-undef
      Message.success("创建成功");
    } else {
      Message.success("创建失败" + res.message);
    }
  }
};
/**
 * 监听题目内容变化
 * @param value
 */
const onContentChange = (value: string) => {
  form.value.content = value;
};
/**
 * 监听答案变化
 * @param value
 */
const onAnswerChange = (value: string) => {
  form.value.answer = value;
};
/**
 * 新增测试用例
 */
const handleAdd = () => {
  form.value.judgeCase.push({
    input: "",
    output: "",
  });
};
/**
 * 删除测试用例
 */
const handleDelete = (index: number) => {
  form.value.judgeCase.splice(index, 1);
};
</script>

<style scoped>
#global {
}
</style>
