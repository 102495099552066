<template>
  <div id="userLayout">
    <a-layout style="min-height: 100vh">
      <a-layout-header class="header">
        <a-space>
          <img class="logo" src="../assets/logo1.png" alt="logo" />
          <div class="title">智能编程学习系统</div>
        </a-space>
      </a-layout-header>
      <a-layout-content class="content">
        <router-view />
      </a-layout-content>
      <a-layout-footer class="footer">
        <a
          href="https://pengit.yuque.com/org-wiki-pengit-uhgcni/vuq1yl"
          target="_blank"
          >在线判题系统：@软工21101</a
        >
      </a-layout-footer>
    </a-layout>
  </div>
</template>

<style>
#userLayout {
  text-align: center;
}

#userLayout .header {
}

#userLayout .content {
  //background: linear-gradient(to right, #bbb, #fff);
  margin-bottom: 16px;
  padding: 20px;
}

#userLayout .footer {
  text-align: center;
  padding: 16px;
  left: 0;
  right: 0;
  bottom: 0;
  position: sticky;
}

#userLayout .title {
  color: #444;
  margin-left: 10px;
  font-size: 24px;
}

#userLayout .logo {
  height: 64px;
  width: 64px;
}
</style>

<script setup lang="ts"></script>
