<template>
  <a-row id="globalHealder" align="center" :wrap="false">
    <a-col flex="auto">
      <div id="globalHealder">
        <a-menu
          mode="horizontal"
          :default-selected-keys="selectedKeys"
          @menu-item-click="doMenuClick"
        >
          <a-menu-item
            key="0"
            :style="{ padding: 0, marginRight: '38px' }"
            disabled
          >
            <div class="title-bar">
              <img class="logo" src="../assets/logo1.png" />
              <div class="title">智能编程学习系统</div>
            </div>
          </a-menu-item>

          <a-menu-item
            v-for="item in visibleRoutes"
            :key="item.path"
            @menu-item-click="doMenuClick"
          >
            {{ item.name }}
          </a-menu-item>
        </a-menu>
      </div>
    </a-col>
    <a-col flex="100px">
      <a-dropdown position="bottom">
        <a-button>
          {{ store.state.user?.loginUser?.userName ?? "未登录" }}
        </a-button>
        <template #content>
          <a-doption @click="userCenter">用户中心</a-doption>
          <a-doption @click="doLogout">退出登录</a-doption>
        </template>
      </a-dropdown>
    </a-col>
  </a-row>
</template>
<script setup lang="ts">
import { routes } from "../router/routes";
import { useRouter } from "vue-router";
import { computed, ref } from "vue";
import { useStore } from "vuex";
import checkAccess from "@/access/checkAccess";
import { UserControllerService } from "../../backapi";

const store = useStore();
const loginUser = store.state.user.loginUser;
//测试全局状态
// setTimeout(() => {
//   store.dispatch("user/getLoginUser", {
//     userName: "小彭同学",
//     userRole: ACCESS_ENUM.ADMIN,
//   });
// }, 3000);

//默认主页
const selectedKeys = ref(["/"]);
const router = useRouter();
//路由跳转后，更新选中的菜单项
router.afterEach((to, from, failure) => {
  selectedKeys.value = [to.path];
});

//展示在菜单的路由数组
const visibleRoutes = computed(() => {
  return routes.filter((item, index) => {
    if (item?.meta?.hideInMenu) {
      return false;
    }
    if (
      !checkAccess(store.state.user.loginUser, item?.meta?.access as string)
    ) {
      return false;
    }
    return true;
  });
});
const userCenter = () => {
  router.push({
    path: "/question/userCenter",
  });
};
const doLogout = async () => {
  //退出请求
  const res = await UserControllerService.userLogoutUsingPost();
  //退出成功，跳转到登录页
  if (res.code === 0) {
    router.push({
      path: "/user/login",
      replace: true,
    });
  } else {
    router.push({
      path: "/user/login",
      replace: true,
    });
    alert(res.message);
  }
};
const doMenuClick = (key: string) => {
  router.push({
    path: key,
  });
};
</script>

<style>
.title-bar {
  display: flex;
  align-items: center;
}

.title {
  color: #444;
  margin-left: 10px;
  font-size: 20px;
}

.logo {
  height: 55px;
}
</style>
