<template>
  <div id="manageQuestionView">
    <a-table
      :ref="tableRef"
      :columns="columns"
      :data="dataList"
      :filter-icon-align-left="true"
      :scroll="{ x: 600, y: 600 }"
      :pagination="{
        showTotal: true,
        pageSize: searchParams.pageSize,
        current: searchParams.current,
        total,
        showJumper: true,
        showPageSize: true,
      }"
      @page-change="onPageChange"
    >
      <template #createTime="{ record }">
        {{ moment(record.createTime).format("YYYY-MM-DD") }}
      </template>
      <template #rate="{ record }">
        <a-space direction="vertical" size="large" :style="{ width: '100%' }">
          <a-progress
            v-if="record.rate >= 50"
            size="medium"
            :percent="record.rate / 100"
            :animation="true"
          />
          <a-progress
            v-else
            :percent="record.rate / 100"
            :animation="true"
            size="medium"
            status="warning"
          />
        </a-space>
      </template>
      <template #updateTime="{ record }">
        {{ moment(record.updateTime).format("YYYY-MM-DD HH:mm:ss") }}
      </template>
      <template #userRole="{ record }">
        <a-space>
          <a-tag size="medium" color="red" v-if="record.userRole === 'admin'">
            {{ ` ${record.userRole}` }}
          </a-tag>
          <a-tag size="medium" color="blue" v-if="record.userRole === 'user'">
            {{ ` ${record.userRole}` }}
          </a-tag>
        </a-space>
      </template>
      <template #optional="{ record }">
        <a-space>
          <!--          <a-button type="primary" @click="doUpdate(record)">修改</a-button>-->
          <a-button status="danger" @click="doDelete(record)">删除</a-button>
        </a-space>
      </template>
    </a-table>
  </div>
</template>

<script setup lang="ts">
import { onMounted, ref, watchEffect } from "vue";
import { User, UserControllerService } from "../../../backapi";
import message from "@arco-design/web-vue/es/message";

import { useRouter } from "vue-router";
import moment from "moment";
// import { router } from "@/router/routes";
// import { format } from "date-fns";

const tableRef = ref();

const dataList = ref([]);
const total = ref(0);
const searchParams = ref({
  pageSize: 10,
  current: 1,
});
// 创建一个数组来存储获取到的用户信息
// const userInfos = [];
// const formatDate = (timestamp: number): string => {
//   return format(new Date(timestamp), "yyyy-MM-dd HH:mm:ss");
// };
const loadData = async () => {
  const res = await UserControllerService.listUserVoByPageUsingPost(
    searchParams.value
  );
  if (res.code === 0) {
    // const formattedData = res.data.records.map((item) => ({
    //   ...item,
    //   createTime: formatDate(item.createTime),
    // }));
    // dataList.value = formattedData;

    dataList.value = res.data.records;
    total.value = res.data.total;
  } else {
    message.error("加载失败，" + res.message);
  }
};

/**
 * 监听 searchParams 变量，改变时触发页面的重新加载
 */
watchEffect(() => {
  loadData();
});

/**
 * 页面加载时，请求数据
 */
onMounted(() => {
  loadData();
});

const columns = [
  // {
  //   title: "用户id",
  //   dataIndex: "userId",
  //   align: "center",
  //   sortable: {
  //     sortDirections: ["ascend", "descend"],
  //   },
  // },
  {
    title: "用户账号",
    dataIndex: "userAccount",
    align: "center",
  },
  {
    title: "用户名",
    dataIndex: "userName",
    align: "center",
  },
  {
    title: "简介",
    dataIndex: "userProfile",
    align: "center",
  },
  {
    title: "通过率",
    dataIndex: "rate",
    align: "center",
    slotName: "rate",
    sortable: {
      sortDirections: ["ascend", "descend"],
    },
  },
  {
    title: "角色",
    dataIndex: "userRole",
    align: "center",
    slotName: "userRole",
  },
  {
    title: "创建时间",
    dataIndex: "createTime",
    align: "center",
    slotName: "createTime",
    sortable: {
      sortDirections: ["ascend", "descend"],
    },
  },
  {
    title: "修改时间",
    dataIndex: "updateTime",
    align: "center",
    slotName: "updateTime",
    sortable: {
      sortDirections: ["ascend", "descend"],
    },
  },
  {
    title: "操作",
    slotName: "optional",
    align: "center",
  },
];

const onPageChange = (page: number) => {
  searchParams.value = {
    ...searchParams.value,
    current: page,
  };
};

/**
 * 删除
 * @param user
 */
const doDelete = async (user: User) => {
  const res = await UserControllerService.deleteUserUsingPost({
    id: user.id,
  });
  if (res.code === 0) {
    message.success("删除成功");
    await loadData();
  } else {
    message.error("删除失败");
  }
};

const router = useRouter();

// /**
//  * 修改 / 更新操作
//  * @param user
//  */
// const doUpdate = (user: User) => {
//   router.push({
//     path: "/question/update",
//     query: {
//       id: user.id,
//     },
//   });
// };
</script>

<style scoped>
.cell-ellipsis {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

#manageQuestionView {
}
</style>
