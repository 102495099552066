<template>
  <div id="userView">
    <div :style="{ background: 'var(--color-fill-2)', padding: '28px' }">
      <a-page-header
        :onBack="() => $router.push('/')"
        :style="{ background: 'var(--color-bg-2)' }"
        title="个人中心"
      >
        <template #subtitle>
          <a-space>
            <span>ArcoDesign Vue 2.0</span>
            <a-tag color="red" size="small">{{ form.userName }}</a-tag>
          </a-space>
        </template>

        <a-form
          ref="formRef"
          :size="form.size"
          :model="form"
          justify="between"
          :style="{ width: '400px' }"
          @submit="handleSubmit"
        >
          <a-form-item field="userName" label="昵称">
            <a-input v-model="form.userName" style="width: 200px" />
          </a-form-item>
          <a-form-item field="userAccount" label="账号">
            <a-input v-model="form.userAccount" style="width: 200px" />
          </a-form-item>
          <a-form-item field="userProfile" label="简签">
            <a-input v-model="form.userProfile" style="width: 200px" />
          </a-form-item>
          <a-form-item field="userRole" label="角色">
            <a-input style="width: 200px" v-model="form.userRole" />
          </a-form-item>
          <a-form-item field="rate" label="通过数">
            <a-input style="width: 200px" v-model="form.passNum"></a-input>
          </a-form-item>
          <a-form-item field="rate" label="提交数">
            <a-input style="width: 200px" v-model="form.submitNum"></a-input>
          </a-form-item>
          <a-form-item field="rate" label="通过率">
            <a-input style="width: 200px" v-model="rates"></a-input>
          </a-form-item>

          <!--          <a-form-item>-->
          <!--            <a-space>-->
          <!--              <a-button @click="doSubmit" type="primary" style="min-width: 70px"-->
          <!--                >修 改-->
          <!--              </a-button>-->
          <!--            </a-space>-->
          <!--          </a-form-item>-->
        </a-form>
      </a-page-header>
    </div>
  </div>
</template>
<script setup lang="ts">
import { onMounted, ref } from "vue";
import { UserControllerService, UserVO } from "../../../backapi";
import { Message } from "@arco-design/web-vue";
import { useRoute, useRouter } from "vue-router";

const router = useRouter();
const route = useRoute();
const doSubmit = async () => {
  console.log("submit");
  const res = await UserControllerService.updateMyUserUsingPost(form.value);
  if (res.code === 0) {
    // eslint-disable-next-line no-undef
    Message.success("更新成功");
    //更新成功返回题目管理页面
    await router.push({
      path: "/question/manage",
      replace: true,
    });
  } else {
    Message.success("更新失败" + res.message);
  }
};
const rates = ref(0);
const form = ref({
  passNum: 0,
  submitNum: 0,
} as UserVO);
const loadData = async () => {
  const r = await UserControllerService.getLoginUserUsingGet();
  const id = r.data?.id;
  const res = await UserControllerService.getLoginUserVoCommentByIdUsingGet();
  if (res.code === 0) {
    form.value = res.data as any;
    rates.value = res.data.rate + "%";
    console.log(form.value);
  } else {
    Message.error("加载失败，" + res.message);
  }
};

onMounted(() => {
  loadData();
});
</script>

<style scoped>
#userView {
}
</style>
